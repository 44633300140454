<template>
    <div>
        <b-overlay :show="showLoading" no-wrap />
        <vue-good-table style="margin-top:20px;" :columns="columns" :rows="rows" :rtl="direction" :search-options="{
            enabled: true,
            externalQuery: searchTerm
        }" :pagination-options="{
                enabled: true,
                perPage: pageLength
            }">
            <template slot="table-row" slot-scope="props">

                <span v-if="props.column.field === 'statut'">
                    <span>
                        {{ changetext(props.row.statut) }}
                    
                    </span>
                </span>

                <span v-else-if="props.column.field === 'actions'">
                    <span>
                        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                            </template>
                            <b-dropdown-item v-if ="props.row.statut != 'EN_ERREUR'" @click="visualise(props.row.id, props.row)">
                                <feather-icon class="mr-50" icon="SearchIcon"/>
                                <span>Voir détail</span>
                            </b-dropdown-item>

                            <b-dropdown-item v-if ="props.row.statut == 'EN_ERREUR'" @click="deleteFiche(props.row.id)">
                                <feather-icon class="mr-50" icon="SearchIcon"/>
                                <span>Supprimer</span>
                            </b-dropdown-item>
                          
                            <b-dropdown-item @click="displayDocument(props.row.document_id, props.row.nom, props.row.nom_custom)">
                                <feather-icon   icon="PaperclipIcon" class="mr-50" />
                                <span>Télécharger</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </span>
                </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                  <b-row class="mt-2 align-items-center">
                      <b-col md="6" lg="5" xxl="3">
                        <div class="d-flex align-items-center mb-0 mt-1">
                          <span class="text-nowrap"> Affichage 1 à </span>
                          <b-form-select
                            v-model="pageLength"
                            :options="['5', '10', '15']"
                            class="mx-1"
                            @input="(value) => props.perPageChanged({ currentPerPage: value })
                                "
                          />
                          <span class="text-nowrap">
                            de {{ props.total }} entrées
                          </span>
                        </div>
                      </b-col>
                    </b-row>

                <b-row class="mt-2 align-items-center">
                    <b-col>
                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                            last-number align="right" prev-class="prev-item" next-class="next-item"
                            @input="value => props.pageChanged({ currentPage: value })">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </template>

          

            <div class="text-center align-middle" slot="emptystate">
                <span>Aucune donn&eacute;e disponible dans le tableau</span>
            </div>
        </vue-good-table>

    </div>
</template>
  
<script>
import flatPickr from "vue-flatpickr-component";
import { BPagination, BFormGroup, VBTooltip, BFormInput, BFormSelect, BFormSelectOption, BRow, BCol, BButton, BForm, BCard, BDropdown, BDropdownItem, BOverlay, BInputGroup, BInputGroupAppend, VBToggle } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import moment from "moment";
export default {
    components: {
        VueGoodTable,
        BPagination,
        BFormGroup,
        BFormInput,
        BForm,
        BFormSelect,
        BInputGroup,
        BInputGroupAppend,
        BRow,
        BCol,
        BButton,
        BCard,
        BDropdown,
        BDropdownItem,
        BOverlay,
        BFormSelectOption,
        VBTooltip,
        VBToggle,
        Ripple,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,

    },
    directives: {
        'b-toggle': VBToggle,
        'b-tooltip': VBTooltip,
        Ripple
    },
    data() {
        return {
            pageLength: 5,
            currentUser: JSON.parse(localStorage.getItem('userData')),
            dir: false,
            columns: [
                 {
                    label: 'ID',
                    field: 'id',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                },
                {
                    label: 'Date d\'import',
                    field: 'dateFichier',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                },
                {
                    label: 'Assureur',
                    field: 'nom_assureur',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                },
                {
                    label: 'Statut du traitement',
                    field: 'statut',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                }
            ],
            rows: [],
            rowsConst: [],
            searchTerm: '',
            showLoading: false
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        }
    },
    watch: {
        ficheId: {
            deep: true,
            immediate: true,
            handler(val) {
                if ( val == 0) {
                    this.fetchData(val)
                }
             
            }
        },
        reAffiche: {
            deep: true,
            immediate: true,
            handler(val) {
                if (val != 0) {
                    this.fetchData(val)
                }

            }
        },

    },
    props: {
        ficheId: {
            type: Number,
            default: 0,
            required: false
        },
        reAffiche: {
            type: Number,
            default: 0,
            required: false
        },
    },
    mounted() {

    },
    methods: {
        changetext(text) {
            let variable = ''
            switch (text) {
                case 'EDITABLE':
                    variable = 'Editable'
                    break;
                case 'EDITE':
                    variable = 'Edité'
                    break;
                case 'EN_ERREUR':
                    variable = 'En erreur'
                    break;
                case 'EN_COMPTA':
                    variable = 'En compta'
                    break;
                case 'EN_GED':
                    variable = 'En GED'
                break;
                default:
                    variable = ''
            }

            return variable
        },
        displayDocument(id, name, NomDuDocument) {

            var nomDocToDownload = ""
            if (NomDuDocument ) {
                nomDocToDownload = NomDuDocument
            } else {
                nomDocToDownload = name
            }
            console.log(nomDocToDownload)

            this.$http
                .get(`/document/generate_base64_for_document/${id}`)
                .then(res => {
                    if (res.data.success) {
                        if (res.data.data.isDownload) {
                            this.downloadDocument(res.data.data.base64, nomDocToDownload)
                        } 
                    } else {
                        this.messageToast(res.data.message, 'Erreur', "danger")
                    }

                })
                .catch(err => {
                    this.messageToast(err.response.data.errors[0], 'Erreur', "danger")
                    console.error(err)
                })
        },
        downloadDocument(base64, name) {
            if (base64) {
                const a = document.createElement('a')
                a.href = base64
                a.download = name
                a.click()
                this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
            } else {
                this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
            }
        },
        fetchData(reaffiche = 0) {
            this.showLoading = true
            this.rows = []
            this.$http.get(`/bordereau_bii/fetchDataFicheBordereau`)
            .then(res => {
                if (res.data.success) {
                    this.rows = res.data.data
                    this.showLoading = false
                    if(reaffiche == 0)
                        this.$emit('find-id', null)
                }
            })
            .catch(err => {
                this.showLoading = false
                console.error(err)
            })
        },
        download(data) {
            
        },
        visualise(id,data) {
            this.$emit('find-data',data)
            this.$emit('find-id',id)
        },
        deleteFiche(id){
            this.showLoading = true
            this.$http.post("bordereau_bii/deleteFicheBordero", { id: id })
            .then(res => {
                if (res.data.success) {
                    this.showLoading = false
                    this.$emit('refresh-data')
                }
            })
            .catch(err => {
                this.showLoading = false
                console.error(err)
            })

        }
    }
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
  